export default {
  getStep1(state) {
    return state.step1;
  },
  getStep2(state) {
    return state.step2;
  },
  getStep3(state) {
    return state.step3;
  },
  getStep4(state) {
    return state.step4;
  },
  getActiveStep(state) {
    return state.activeStep;
  },
};
