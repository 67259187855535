import actions from "./actions.js";

const notificationsModule = {
  state() {
    return {};
  },
  actions,
};

export default notificationsModule;
