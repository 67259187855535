import actions from "./actions.js";

const fileModule = {
  state() {
    return {};
  },
  actions,
};

export default fileModule;
