import actions from "./actions.js";

const calendarModule = {
  state() {
    return {};
  },
  actions,
};

export default calendarModule;
